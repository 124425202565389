import React from 'react'
import LegalPageLayout from '../../components/LegalPageLayout/legalPageLayout'

const Terms = () => {

    const bodyContent = () => <div>
                        
                        <p style={{fontStyle: 'italic'}}>ATTENTION: This notice applies to the entire contents of this website under the domain www.theinclab.com (“Website”) and to any correspondence by e-mail between us and you. Please read these terms carefully before using this Website. Using this Website indicates that you accept these terms. If you do not accept these terms, do not use this Website. </p>
                        
						<h3>1. INTRODUCTION</h3>
						<p>1.1 You may access most areas of this Website without registering your details with us.</p>
						<p>1.2 By accessing any part of this Website, you shall be deemed to have accepted this legal notice in full. If you do not accept this legal notice in full, you must leave this Website immediately.</p>
						<p>1.3 TheIncLab (TIL) may revise this legal notice at any time by updating this posting. You should check this Website from time to time to review the current legal notice, because it is binding to you. Certain provisions of this legal notice may be superseded by expressly designated legal notices or terms located on particular pages on this Website.</p>
						<h3>2. LICENSE</h3>
						<p>2.1 You are permitted to print and download extracts from this Website for your own use on the following basis:</p>
						<ul>
							<li>(a) no documents or related graphics on this Website are modified in any way;</li>
							<li>(b) no graphics on this Website are used separately from accompanying text;</li>
							<li>(c) TheIncLab copyright and trade mark notices and this permission notice appear in all copies; and</li>
							<li>(d) any such use is for your own personal use only and no materials shall be used for any commercial purposes whatsoever.</li>
						</ul>	
							
						<p>2.2 Unless otherwise stated, the copyright and other intellectual property rights in all material on this Website (including without limitation photographs and graphical images) are owned by TheIncLab or its licensors. For the purposes of this legal notice, any use of extracts from this Website other than in accordance with paragraph 2.1 above for any purpose is prohibited. If you breach any of the terms in this legal notice, your permission to use this Website automatically terminates and you must immediately destroy any downloaded or printed extracts from this Website. You may also be liable to indemnify us against any losses we incur as a result of the use of this Website or any information extracted from it.
						</p><p>2.3 Subject to paragraph 2.1, no part of this Website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service without TIL’s prior written permission. Any rights not expressly granted in these terms are reserved.</p>
						<h3>3. SERVICE ACCESS</h3>
						<p>3.1 While TheIncLab tries to ensure that this Website is normally available 24 hours a day, TheIncLab shall not be liable if for any reason this Website is unavailable at any time or for any period. Access to this Website may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond TheIncLab’s control.</p>
						<h3>4. VISITOR MATERIAL AND CONDUCT</h3>
						<p>4.1 Other than personally identifiable information, which is covered under the Privacy Policy, any material you transmit or post to this Website shall be considered non-confidential and non-proprietary. TIL shall have no obligations with respect to such material. TIL and its designees shall be free to copy, disclose, distribute, incorporate, and otherwise use such material and all data, images, sounds, text, and other things embodied therein for any and all commercial or non-commercial purposes. This will not apply to any email you send us via the Website requesting advice, which shall be treated on receipt by TIL as confidential. However, you are reminded that email is not necessarily a secure method of transmission and you are responsible for ensuring any email is correctly addressed. Where your correspondence contains any particularly sensitive or confidential information, we recommend you contact us prior to transmitting such information in order to discuss the appropriate arrangements.</p>
						<p>4.2 You are prohibited from posting or transmitting to or from this Website any material:</p>
						<ul>
							<li>(a) that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience; or</li>
							<li>(b) for which you have not obtained all necessary licensees and/or approvals; or</li>
							<li>(c) which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party, in the US or any other country in the world;</li>
							<li>(d) which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data).</li>
						</ul>
				
						<p>4.3 You may not misuse the Website (including, without limitation, by hacking).</p>
						<p>4.4 TIL shall fully co-operate with any law enforcement authorities or court order requesting or directing TIL to disclose the identity or locate anyone posting any material in breach of paragraph 4.2 or paragraph 4.3.</p>
						<h3>5.LINKS TO AND FROM OTHER WEBSITES</h3>
						<p>Links to third party websites on this Website are provided solely for your convenience. TIL has not reviewed all of these third party websites and does not control and is not responsible for these websites or their content or availability. TIL therefore does not endorse or make any representations about them, or any material found there, or any results that may be obtained from using them. If you decide to access any of the third party websites linked to this Website, you do so entirely at your own risk.</p>
						<h3>6. DISCLAIMER</h3>
						<p>6.1 While TIL tries to ensure that the information on this Website is correct, TIL does not warrant the accuracy and completeness of the material on this Website. TIL may make changes to the material on this Website, or to the services described in it, at any time without notice. The material on this Website may be out of date, and TIL makes no commitment to update such material.</p>
						<p>6.2 The material on this Website is provided “as is,” without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, TIL provides you with this Website on the basis that TIL excludes all representations, warranties, conditions and other terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose, and the use of reasonable care and skill), which but for this legal notice might have effect in relation to this Website.</p>
						<p>6.3 Where any information is provided on this Website or in any newsletter or similar correspondence we may send you at any time, such materials are provided for general information and reference purposes only and should not be relied upon. You should seek specific legal advice in relation to any query you may have, and we shall not be liable for any action you take or failure to act following a review of the information placed on the Website.</p>
						<h3>7. LIABILITY</h3>
						<p>7.1 TIL, any other party (whether or not involved in creating, producing, maintaining or delivering this Website), and any of TILs partners, employees or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including without limitation, any direct, indirect, punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption, and whether in tort (including without limitation negligence), contract or otherwise) in connection with this Website in any way or in connection with the use, inability to use or the results of use of this Website, any websites linked to this Website or the material on such websites, including but not limited to loss or damage due to viruses that may infect your computer equipment, software, data or other property on account of your access to, use of, or browsing this Website or your downloading of any material from this Website or any websites linked to this Website.</p>
						<h3>8. GOVERNING LAW AND JURISDICTION</h3>
						<p>This legal notice shall be governed by and construed in accordance with US law. Disputes arising in connection with this legal notice shall be subject to the exclusive jurisdiction of the US courts. This Website is based in US. We make no representations that the materials and Website are suitable for users outside US, and if you choose to access the Website from outside of US you are solely responsible for ensuring compliance with all relevant local laws, and you hereby indemnify us from any liability arising in connection with your use and access to the Website.</p>
						<p>&nbsp;</p>
                        <p>Issued: January {new Date().getFullYear()}</p>


    </div>


    return (
        <div>
            <LegalPageLayout 
            bannerImg='/img/careers-hub.jpg'
            bannerTitle='Terms'
            bodyContent={bodyContent()}
            />
        </div>
    )
}

export default Terms
